<template>
  <div>
    <div
      v-if="!castup_option_enable"
      class="text-subtitle-2 pa-1 orange--text orange lighten-5"
    >
      本機能は「エステ魂」に対応しています。※オプション契約で、更新間隔の短縮や更新人数を増加できます。
    </div>
    <div v-else class="text-subtitle-2 pa-1 orange--text orange lighten-5">
      本機能は「エステ魂」に対応しています。
    </div>
    <div
      v-show="castupContent.id == 0"
      class="text-subtitle-2 pa-1 orange--text orange lighten-5"
    >
      設定を保存してから自動更新を開始して下さい。
    </div>
    <div style="position: relative">
      <app-bar
        ref="refAppBar"
        @save="saveButton"
        @setting="settingButton"
        @update_button="updateButton"
      ></app-bar>
      <div class="text-subtitle-2 pa-1 blue--text blue lighten-5">
        <v-icon color="#1976d2" dense>mdi-chat-question</v-icon>
        <a style="text-decoration: underline" @click="jidoukoushinDialog = true"
          >自動更新ボタンについて</a
        >
      </div>
      <v-dialog
        v-model="jidoukoushinDialog"
        scrollable
        width="100%"
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">自動更新ボタンについて</span>
          </v-card-title>
          <v-card-text>
            <ul>
              <li>
                自動更新を開始すると、オプション契約の内容に従って、ランダムな更新間隔とランダムに選ばれたセラピストを更新します。更新の結果、媒体の検索結果において上位表示されます。
              </li>
              <li>
                本画面では、更新対象にしたいセラピストにチェックをつけてください。チェックを外したセラピストは更新対象から外れます。
              </li>
            </ul>
          </v-card-text>
          <v-card-actions>
            <v-container>
              <v-row justify="end">
                <v-col cols="6" sm="3">
                  <general-button
                    btn_color="gray"
                    btn_block
                    @click-event="jidoukoushinDialog = false"
                    ><template v-slot:icon
                      ><v-icon left>mdi-close-circle</v-icon></template
                    >閉じる</general-button
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div style="position: relative">
        <v-overlay
          :value="overlay"
          absolute
          opacity="0.1"
          z-index="4"
        ></v-overlay>
        <v-snackbar v-model="overlay" timeout="-1" color="orange"
          >現在、自動更新中です。編集する場合は停止して下さい。
        </v-snackbar>
        <!-- 更新間隔指定エリア -->
        <div class="bg-green mb-2 pb-4">
          <ValidationObserver ref="observerInterval">
            <v-container fluid>
              <v-row>
                <v-col cols="12" lg="8" class="py-0">
                  <v-subheader>更新対象とするセラピストの設定</v-subheader>
                  <v-select
                    v-model="castupContent.update_type"
                    :items="selectITem.castupTypeItem"
                    success="success"
                    outlined
                    class="pl-4"
                    background-color="white"
                    hide-details
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-subheader
                    >更新内容【現在:{{ plan_text }}】
                    <a
                      style="text-decoration: underline"
                      @click="aboutPlanDialog = true"
                      >オプション契約について</a
                    >
                  </v-subheader>
                  <div class="d-inline-block pl-4">
                    ランダムに
                    {{ min_interval_minute }} 〜
                    {{ max_interval_minute }}分間隔で、対象セラピストの
                    <span v-if="max_castup_num !== 1">
                      {{ min_castup_num }} 〜
                      {{ max_castup_num }}人を更新します。
                    </span>
                    <span v-else>
                      {{ max_castup_num }}
                      人を更新します。
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </ValidationObserver>
        </div>

        <v-dialog
          v-model="aboutPlanDialog"
          scrollable
          width="100%"
          max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">オプション契約について</span>
            </v-card-title>
            <v-card-text>
              セラピスト上位更新機能は、下記のオプションがあります。詳しくは運営までお問い合わせください。
              <div class="mt-2">有料オプション</div>
              <ul>
                <li>更新間隔:1〜5分 セラピスト:1〜2名</li>
                <li>更新間隔:15〜30分 セラピスト:1〜2名</li>
                <li>更新間隔:30〜60分 セラピスト:1〜2名</li>
              </ul>
              <div class="mt-2">無料オプション</div>
              <ul>
                <li>更新間隔:60〜90分 セラピスト:1名</li>
              </ul>
            </v-card-text>
            <v-card-actions>
              <v-container>
                <v-row justify="end">
                  <v-col cols="6" sm="3">
                    <general-button
                      btn_color="gray"
                      btn_block
                      @click-event="aboutPlanDialog = false"
                      ><template v-slot:icon
                        ><v-icon left>mdi-close-circle</v-icon></template
                      >閉じる</general-button
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-container fluid>
          <v-row>
            <v-col cols="12" md="6">
              <div class="text-subtitle-2 pa-1 blue--text blue lighten-5 mb-2">
                対象セラピストにチェックを入れてください
              </div>
              <v-row>
                <v-col cols="6"></v-col>
                <v-col cols="6">
                  <div>
                    <v-text-field
                      v-model="castSearch"
                      append-icon="mdi-magnify"
                      label="（セラピスト名検索）"
                      single-line
                      hide-details
                      clearable
                      dense
                      class="mb-2"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-data-table
                id="cast_table"
                :headers="castHeaders"
                :items="casts"
                :search="castSearch"
                item-key="id"
                show-select
                :single-select="false"
                v-model="castSelected"
                :mobile-breakpoint="0"
                :items-per-page="50"
                :footer-props="{
                  'items-per-page-options': [50, 100, -1],
                  'items-per-page-text': '表示件数',
                }"
                @click:row="rowClick"
              >
                <!-- eslint-disable-next-line -->
                <template v-slot:footer.page-text="props">
                  {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
                    props.itemsLength
                  }}件
                </template>
                <!-- 画像 -->
                <!-- eslint-disable-next-line -->
                <template v-slot:item.image="{ item }">
                  <v-avatar class="ma-1"
                    ><v-img :src="setting.cdn + item.image"
                  /></v-avatar>
                </template>
                <!-- 名前 -->
                <!-- eslint-disable-next-line -->
                <template v-slot:item.name="{ item }">
                  {{ item.name }}
                </template>
                <!-- 公開 -->
                <!-- eslint-disable-next-line -->
                <template v-slot:item.enable="{ item }">
                  <div class="pa-1">
                    <v-chip
                      v-if="item.is_enable"
                      class="ma-2"
                      color="primary"
                      label
                      small
                    >
                      公開中
                    </v-chip>
                    <v-chip v-else class="ma-2" label small> 非公開 </v-chip>
                  </div>
                </template>
                <template v-slot:no-data>
                  セラピストが登録されていません
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" md="6">
              <div
                class="text-subtitle-2 pa-1 orange--text orange lighten-5 mb-2"
              >
                更新したい媒体をチェックをしてください。
              </div>
              <v-row>
                <v-col cols="6"></v-col>
                <v-col cols="6">
                  <div>
                    <v-text-field
                      v-model="shopSiteSearch"
                      append-icon="mdi-magnify"
                      label="（媒体名検索）"
                      single-line
                      hide-details
                      clearable
                      dense
                      class="mb-2"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <v-data-table
                id="shop_site_table"
                :headers="shopSiteHeaders"
                :items="shopSites"
                :search="shopSiteSearch"
                item-key="id"
                show-select
                :single-select="false"
                v-model="shopSiteSelected"
                :mobile-breakpoint="0"
                :items-per-page="50"
                :footer-props="{
                  'items-per-page-options': [50, 100, -1],
                  'items-per-page-text': '表示件数',
                }"
                @click:row="rowClick"
              >
                <!-- eslint-disable-next-line -->
                <template v-slot:footer.page-text="props">
                  {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
                    props.itemsLength
                  }}件
                </template>
                <!-- 名前 -->
                <!-- eslint-disable-next-line -->
                <template v-slot:item.name="{ item }">
                  {{ item.name }}
                </template>
                <template v-slot:no-data> 媒体が登録されていません </template>
                <template v-slot:item.profile="{ item }">
                  <span class="profile_span"
                    >{{ item.profile_1 }}<br />{{ item.profile_2 }}</span
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <!-- 設定ダイアログ -->
        <v-dialog
          v-model="settingDialog"
          scrollable
          persistent
          width="100%"
          max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">設定</span>
            </v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-subheader class="pl-0">
                      更新する時間帯をONにして下さい。デフォルトで9時〜深夜1時がONになっています。
                    </v-subheader>
                    <div
                      v-for="num in time_zone_default_list"
                      :key="num"
                      class="time_zone_box"
                    >
                      <v-btn
                        :color="
                          time_zone_list.find((v) => v == num)
                            ? `primary`
                            : `grey lighten-3`
                        "
                        small
                        elevation="0"
                        class="pa-0 mr-1 mb-1"
                        @click="onClickTimeZone(num)"
                        >{{ num }}時</v-btn
                      >
                    </div>
                    <p>※店舗管理の更新時間帯で設定した内容が優先されます。</p>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-container>
                <v-row justify="end">
                  <v-col cols="6" sm="3">
                    <general-button
                      btn_color="gray"
                      btn_block
                      @click-event="cancelSettingDialog"
                      ><template v-slot:icon
                        ><v-icon left>mdi-close-circle</v-icon></template
                      >キャンセル</general-button
                    >
                  </v-col>
                  <v-col cols="6" sm="3">
                    <general-button
                      btn_type="info"
                      btn_block
                      @click-event="onSubmitSettingDialog"
                      ><template v-slot:icon
                        ><v-icon left>mdi-content-save</v-icon></template
                      >保存</general-button
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  toRefs,
  set,
  onMounted,
  defineComponent,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";
import utilFunc from "@/common/utilFunc.js";
import selectITem from "@/common/selectItem.js";
import appBar from "./appBar.vue";
import router from "@/router/index";

export default defineComponent({
  components: {
    appBar,
  },
  setup(props, ctx) {
    const refAppBar = ref();
    const CastsRepository = repositoryFactory.get("casts");
    const ShopSitesRepository = repositoryFactory.get("shopSites");
    const UpdateCastupContentsRepository = repositoryFactory.get(
      "updateCastupContents"
    );
    const state = reactive({
      castup_option_enable: false,
      castup_option_code: "",
      overlay: false,
      min_castup_num: 1,
      max_castup_num: 1,
      display_is_auto: false,
      // 説明ダイアログ
      jidoukoushinDialog: false,
      aboutPlanDialog: false,
      // 設定ダイアログ
      settingDialog: false,
      time_zone_list: [],
      time_zone_default_list: setting.time_zone_default.split(","),
      // キャスト
      casts: [],
      castSearch: "",
      castSelected: [],
      castHeaders: [
        {
          text: "画像",
          value: "image",
          sortable: false,
          align: "center",
          class: "td_image",
          width: 80,
        },
        {
          text: "セラピスト名",
          value: "name",
          sortable: true,
          class: "td_name",
        },
        { text: "公開/非公開", value: "enable", sortable: true },
      ],
      shopSites: [],
      shopSiteSearch: "",
      shopSiteSelected: [],
      shopSiteHeaders: [
        {
          text: "媒体名",
          value: "name",
          sortable: true,
        },
      ],
      // キャスト上位更新コンテンツ
      castupContent: {
        id: 0,
        shop_id: 0,
        update_type: 1,
        is_auto: false,
        cast_ids: "",
        shop_site_ids: "",
        config: "",
        time_zone: setting.time_zone_default_castup,
        next_update: null,
      },
      min_interval_minute: 60,
      max_interval_minute: 90,
      plan_text: "無料オプション",
    });

    const setOverlay = (overlay) => {
      state.overlay = overlay;
    };

    // 初期化処理
    onMounted(async () => {
      await store.dispatch("loadingIcon/showIcon");

      // 店舗チェック falseの場合ログアウトになる
      const currentShop = store.getters["shops/currentShop"];
      const checkResult = await utilFunc.checkShop(currentShop.id, router);
      if (!checkResult) return;
      // 契約チェック
      if (
        checkResult.valid_option.find(
          (v) => v.split("_")[0] == setting.plan_code_castup_base
        )
      ) {
        state.castup_option_enable = true;
      }
      if (state.castup_option_enable) {
        // オプションコード取得 1 > 2 > 3 の順に優先して取得
        let count = 0;
        count = await utilFunc.getValidOptionCount(
          checkResult.valid_option,
          setting.plan_code_castup_1
        );
        if (count > 0) {
          state.castup_option_code = setting.plan_code_castup_1;
          state.plan_text = setting.plan_code_castup_1_name + ` ${count}契約`;
        } else {
          count = await utilFunc.getValidOptionCount(
            checkResult.valid_option,
            setting.plan_code_castup_2
          );
          if (count > 0) {
            state.castup_option_code = setting.plan_code_castup_2;
            state.plan_text = setting.plan_code_castup_2_name + ` ${count}契約`;
          } else {
            count = await utilFunc.getValidOptionCount(
              checkResult.valid_option,
              setting.plan_code_castup_3
            );
            if (count > 0) {
              state.castup_option_code = setting.plan_code_castup_3;
              state.plan_text =
                setting.plan_code_castup_3_name + ` ${count}契約`;
            }
          }
        }
        // 最大更新人数を設定(有料時)
        state.min_castup_num = setting.min_castup_num * count;
        state.max_castup_num = setting.max_castup_num * count;
      } else {
        // 最大更新人数を設定(無料時)
        state.min_castup_num = setting.max_castup_num_free;
        state.max_castup_num = setting.max_castup_num_free;
      }

      // パラメータセット
      let params = new URLSearchParams();

      state.casts.splice(0);
      params.append("shop_id", currentShop.id);
      await CastsRepository.list(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              state.casts.push(response.data[key]);
            });
          }
        })
        .catch((error) => {
          throw "ERROR:CastsRepository.list (" + error + ")";
        });

      // 更新サイト一覧取得
      state.shopSites.splice(0);
      await ShopSitesRepository.list_castup(params)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              state.shopSites.push({
                "id": response.data[key].id,
                "name":
                  response.data[key].sites_name +
                  (JSON.parse(response.data[key].config).memo != ""
                    ? " [ " + JSON.parse(response.data[key].config).memo + " ] "
                    : ""),
              });
            });
          }
        })
        .catch((error) => {
          throw "ERROR:ShopSitesRepository.list_castup (" + error + ")";
        });

      // キャスト上位更新コンテンツ取得
      const result = await UpdateCastupContentsRepository.get_shop(
        currentShop.id
      )
        .then((response) => {
          if (response.data) {
            return response.data;
          }
        })
        .catch((error) => {
          throw "ERROR:UpdateCastupContentsRepository.get_shop (" + error + ")";
        });

      if (result.length > 0) {
        // レコードあり
        state.castupContent = result[0];
        if (state.castup_option_enable) {
          // 更新間隔を設定
          if (state.castup_option_code == setting.plan_code_castup_1) {
            state.min_interval_minute = 1;
            state.max_interval_minute = 5;
          } else if (state.castup_option_code == setting.plan_code_castup_2) {
            state.min_interval_minute = 15;
            state.max_interval_minute = 30;
          } else if (state.castup_option_code == setting.plan_code_castup_3) {
            state.min_interval_minute = 30;
            state.max_interval_minute = 60;
          }
        } else {
          state.min_interval_minute = 60;
          state.max_interval_minute = 90;
        }
        state.display_is_auto = state.castupContent.is_auto;
        // キャストと店舗媒体のリストにセット
        const castTmp = state.castupContent.cast_ids.split(",");
        for (let i = 0; i < castTmp.length; i++) {
          const cast = state.casts.find((v) => v.id == castTmp[i]);
          if (cast) {
            state.castSelected.push(cast);
          }
        }
        state.shopSiteTmp = state.castupContent.shop_site_ids.split(",");
        for (let i = 0; i < state.shopSiteTmp.length; i++) {
          const shopSite = state.shopSites.find(
            (v) => v.id == state.shopSiteTmp[i]
          );
          if (shopSite) {
            state.shopSiteSelected.push(shopSite);
          }
        }
        // ボタン状態セット
        refAppBar.value.action({ func: "editmode_set", mode: "update" });
        refAppBar.value.action({
          func: "auto_set",
          mode: state.display_is_auto,
        });
      } else {
        if (state.castup_option_enable) {
          // 更新間隔を設定
          if (state.castup_option_code == setting.plan_code_castup_1) {
            state.min_interval_minute = 1;
            state.max_interval_minute = 5;
          } else if (state.castup_option_code == setting.plan_code_castup_2) {
            state.min_interval_minute = 15;
            state.max_interval_minute = 30;
          } else if (state.castup_option_code == setting.plan_code_castup_3) {
            state.min_interval_minute = 30;
            state.max_interval_minute = 60;
          }
        }
        // レコードなし
        state.display_is_auto = false;
        // キャストと店舗媒体を全選択状態にする
        state.castSelected = state.casts;
        state.shopSiteSelected = state.shopSites;
        // キャスト上位更新コンテンツの初期値をセット
        state.castupContent.shop_id = currentShop.id;
        // ボタン状態セット
        refAppBar.value.action({ func: "editmode_set", mode: "new" });
      }

      setOverlay(state.display_is_auto);

      await store.dispatch("loadingIcon/hideIcon");
    });

    const rowClick = (item, row) => {
      row.select(!row.isSelected);
    };

    const updateButton = async () => {
      if (state.display_is_auto == false) {
        if (state.castSelected.length == 0) {
          alert("上位更新したいセラピストにチェックがありません。");
          return false;
        }
        if (state.shopSiteSelected.length == 0) {
          alert("上位更新したい媒体にチェックがありません。");
          return false;
        }

        // 自動更新を開始できるかチェック update_next_checkの処理を入れる
        let check_result =
          await UpdateCastupContentsRepository.update_next_check(
            state.castupContent.id
          )
            .then((response) => {
              if (response.data) {
                return response.data.result;
              }
            })
            .catch((error) => {
              throw (
                "ERROR:updateContentsRepository.update_next_check (" +
                error +
                ")"
              );
            });
        if (!check_result) {
          alert(
            "更新設定が無い、又は、次回の更新時刻が取得できない為、自動更新を開始できません"
          );
          return false;
        }

        if (
          !window.confirm(
            "自動更新を開始してよろしいですか？\n5分後から(又は、指定した時間帯になったら)更新がスタートします。"
          )
        ) {
          return false;
        }

        // 次回更新時刻を取得 next_update
        const update_next =
          await UpdateCastupContentsRepository.get_next_update(
            state.castupContent.id
          )
            .then((response) => {
              if (response.data) {
                return response.data.next_update;
              }
            })
            .catch((error) => {
              throw (
                "ERROR:updateContentsRepository.get_next_update (" + error + ")"
              );
            });

        if (update_next == null) {
          alert("次回更新時刻が取得できない為、自動更新を開始できません");
          return false;
        }

        state.castupContent.next_update = update_next;
      } else {
        if (!window.confirm("自動更新を停止してよろしいですか？")) {
          return false;
        }
        state.castupContent.next_update = null;
      }
      state.display_is_auto = !state.display_is_auto;

      // 自動更新ボタンの状態を変更
      if (await saveButton(false)) {
        refAppBar.value.action({
          func: "auto_set",
          mode: state.display_is_auto,
        });
        setOverlay(state.display_is_auto);
      }
    };

    // 更新内容を保存する
    // isCheck:確認メッセージを出す時、true
    // castupOnly:更新コンテンツテーブルのみ保存したい時、true（設定ダイアログ保存時、trueにする）
    const saveButton = async (isCheck = true, timezoneOnly = false) => {
      // 入力チェック
      if (isCheck) {
        // 更新前の確認
        if (!window.confirm("設定を保存します。よろしいですか？")) {
          return false;
        }
      }

      // キャスト上位更新コンテンツの保存
      if (!timezoneOnly) {
        state.castupContent.is_auto = state.display_is_auto;
        set(
          state.castupContent,
          "cast_ids",
          state.castSelected.map((v) => v.id).join()
        );
        set(
          state.castupContent,
          "shop_site_ids",
          state.shopSiteSelected.map((v) => v.id).join()
        );
      }

      await saveCastupContent();

      return true;
    };

    const saveCastupContent = async () => {
      await store.dispatch("loadingIcon/showIcon");

      const params = state.castupContent;
      const result = await UpdateCastupContentsRepository.save(params)
        .then((response) => {
          if (response.data) {
            return response.data;
          } else {
            return false;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:castUpdate/index.vue/bulkUpdate SynchroRepository.sync_cast (" +
            error +
            ")"
          );
        });

      if (result) {
        state.castupContent.id = result.id;
        refAppBar.value.action({ func: "editmode_set", mode: "update" });
      }

      await store.dispatch("loadingIcon/hideIcon");
    };

    // 設定ボタンクリック 時間帯設定ダイアログ表示
    const settingButton = async () => {
      if (state.castupContent.time_zone.length == 0) {
        state.time_zone_list = [];
      } else {
        state.time_zone_list = state.castupContent.time_zone.split(",");
      }

      state.settingDialog = true;
    };

    // 設定ダイアログ閉じる
    const cancelSettingDialog = () => {
      state.time_zone_list.splice(0);
      state.settingDialog = false;
    };

    // 設定ダイアログ保存
    const onSubmitSettingDialog = async () => {
      if (state.time_zone_list.length == 0) {
        set(state.castupContent, "time_zone", "");
      } else {
        set(state.castupContent, "time_zone", state.time_zone_list.join());
      }
      await saveButton(false, true);

      cancelSettingDialog();
    };

    // 更新時間帯の数字クリック時
    const onClickTimeZone = (num) => {
      const result = state.time_zone_list.indexOf(num);
      if (result === -1) {
        state.time_zone_list.push(num);
      } else {
        state.time_zone_list.splice(result, 1);
      }
    };

    return {
      refAppBar,
      setting,
      utilDate,
      selectITem,
      ...toRefs(state),
      rowClick,
      saveButton,
      updateButton,
      settingButton,
      cancelSettingDialog,
      onSubmitSettingDialog,
      onClickTimeZone,
    };
  },
});
</script>

<style scoped>
.select_item {
  width: 4em;
}
.bg-green {
  background-color: #e8f5e9;
}
.time_zone_box {
  display: inline-block;
}
.plan_box {
  border-radius: 4px;
  border: 2px solid #333;
  padding: 8px;
  background-color: white;
}
</style>
